import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CartItem {
  vehicle_id: number;
  start_date: string;
  end_date: string;
  daily_rate: number;
  vehicle_name: string;
  total_price: number;
  duration_discount: number;
  additional_charges?: {
    id: number;
    name: string;
    amount: number;
  }[];
}

interface CartState {
  items: CartItem[];
}

const initialState: CartState = {
  items: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartItem>) => {
      state.items.push(action.payload);
    },
    removeFromCart: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter(item => item.vehicle_id !== action.payload);
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const { addToCart, removeFromCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
