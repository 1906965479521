import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { FaArrowRight, FaCarSide, FaCreditCard, FaMapMarkedAlt, FaGasPump, FaCar } from 'react-icons/fa';
import SearchBar from '../components/SearchBar';

const HomePage: React.FC = () => {
  const { isDarkMode } = useSelector((state: RootState) => state.theme);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      {/* Hero Section */}
      <div className="relative min-h-screen flex items-center">
        {/* Background Image with Overlay */}
        <div className="absolute inset-0 z-0">
          <img
            src="/hero-bg1.svg"
            alt="Luxury cars"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-black/50"></div>
        </div>

        {/* Hero Content */}
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-4xl mb-8">
            <h1 className="text-5xl md:text-7xl font-bold text-white mb-6 leading-tight">
              Find Your Perfect <span className="text-secondary-500">Car Rental</span> in Dubai
            </h1>
            <p className={`text-xl ${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-8 max-w-2xl`}>
              Experience luxury and comfort with our premium fleet of vehicles. Best prices guaranteed.
            </p>
          </div>

          {/* Search Bar */}
          <div className="w-full max-w-[90rem] mx-auto">
            <SearchBar />
          </div>
        </div>
      </div>

      {/* Luxury Fleet Section */}
      <div className={`py-20 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className={`text-4xl md:text-5xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Our <span className="text-primary-500">Luxury</span> Fleet
            </h2>
            <p className={`text-xl ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} max-w-3xl mx-auto`}>
              Experience the epitome of luxury with our premium collection of high-end vehicles
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Luxury Car Cards */}
            <div className={`group rounded-xl overflow-hidden ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg transform hover:-translate-y-2 transition-all`}>
              <div className="relative h-64">
                <img src="/luxury-car1.jpg" alt="Mercedes-Benz S-Class" className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent group-hover:from-black/90 transition-all">
                  <div className="absolute bottom-4 left-4 right-4">
                    <h3 className="text-white text-2xl font-bold mb-2">Mercedes-Benz S-Class</h3>
                    <p className="text-gray-200">From AED 1,200/day</p>
                  </div>
                </div>
              </div>
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center space-x-2">
                    <FaCar className="text-primary-500" />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Luxury Sedan</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaGasPump className="text-primary-500" />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Hybrid</span>
                  </div>
                </div>
                <button onClick={() => window.location.href = '/vehicles'} className="w-full py-3 bg-primary-500 text-white rounded-lg font-medium hover:bg-primary-600 transition-colors">
                  Book Now
                </button>
              </div>
            </div>

            <div className={`group rounded-xl overflow-hidden ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg transform hover:-translate-y-2 transition-all`}>
              <div className="relative h-64">
                <img src="/luxury-car2.jpg" alt="BMW 7 Series" className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent group-hover:from-black/90 transition-all">
                  <div className="absolute bottom-4 left-4 right-4">
                    <h3 className="text-white text-2xl font-bold mb-2">BMW 7 Series</h3>
                    <p className="text-gray-200">From AED 1,100/day</p>
                  </div>
                </div>
              </div>
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center space-x-2">
                    <FaCar className="text-primary-500" />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Executive</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaGasPump className="text-primary-500" />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Petrol</span>
                  </div>
                </div>
                <button onClick={() => window.location.href = '/vehicles'} className="w-full py-3 bg-primary-500 text-white rounded-lg font-medium hover:bg-primary-600 transition-colors">
                  Book Now
                </button>
              </div>
            </div>

            <div className={`group rounded-xl overflow-hidden ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg transform hover:-translate-y-2 transition-all`}>
              <div className="relative h-64">
                <img src="/luxury-car3.jpg" alt="Porsche Panamera" className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent group-hover:from-black/90 transition-all">
                  <div className="absolute bottom-4 left-4 right-4">
                    <h3 className="text-white text-2xl font-bold mb-2">Porsche Panamera</h3>
                    <p className="text-gray-200">From AED 1,500/day</p>
                  </div>
                </div>
              </div>
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center space-x-2">
                    <FaCar className="text-primary-500" />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Sports</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaGasPump className="text-primary-500" />
                    <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>Hybrid</span>
                  </div>
                </div>
                <button onClick={() => window.location.href = '/vehicles'} className="w-full py-3 bg-primary-500 text-white rounded-lg font-medium hover:bg-primary-600 transition-colors">
                  Book Now
                </button>
              </div>
            </div>
          </div>

          {/* View All Button */}
          <div className="text-center mt-12">
            <button
              onClick={() => window.location.href = '/vehicles'}
              className="inline-flex items-center space-x-2 px-8 py-4 bg-primary-500 text-white rounded-lg font-medium hover:bg-primary-600 transform hover:scale-105 transition-all"
            >
              <span>View All Luxury Vehicles</span>
              <FaArrowRight />
            </button>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className={`py-20 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className={`text-4xl md:text-5xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Why Choose <span className="text-primary-500">Alwasl</span>
            </h2>
            <p className={`text-xl ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} max-w-3xl mx-auto`}>
              Experience unparalleled service and luxury with Dubai's premier car rental service
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className={`p-8 rounded-xl ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} transform hover:-translate-y-2 transition-all`}>
              <div className="text-primary-500 text-4xl mb-6">
                <FaCarSide />
              </div>
              <h3 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Premium Fleet</h3>
              <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                Access to the latest luxury vehicles from top manufacturers, maintained to the highest standards.
              </p>
            </div>

            <div className={`p-8 rounded-xl ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} transform hover:-translate-y-2 transition-all`}>
              <div className="text-primary-500 text-4xl mb-6">
                <FaCreditCard />
              </div>
              <h3 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Flexible Payment</h3>
              <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                Multiple payment options and transparent pricing with no hidden charges.
              </p>
            </div>

            <div className={`p-8 rounded-xl ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} transform hover:-translate-y-2 transition-all`}>
              <div className="text-primary-500 text-4xl mb-6">
                <FaMapMarkedAlt />
              </div>
              <h3 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Convenient Locations</h3>
              <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                Multiple pickup and drop-off locations across Dubai for your convenience.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="relative py-20">
        <div className="absolute inset-0 z-0">
          <img src="/cta-bg.svg" alt="Luxury car background" className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-gradient-to-r from-primary-500/90 to-primary-700/90"></div>
        </div>
        <div className="relative container mx-auto px-4 text-center">
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Ready to Experience Luxury?
          </h2>
          <p className="text-xl text-white/90 mb-8 max-w-2xl mx-auto">
            Book your dream car today and enjoy the ultimate driving experience in Dubai
          </p>
          <button
            onClick={() => window.location.href = '/vehicles'}
            className="inline-flex items-center space-x-3 px-8 py-4 bg-white text-primary-600 rounded-lg font-medium hover:bg-gray-100 transform hover:scale-105 transition-all"
          >
            <FaCar />
            <span>Browse Our Fleet</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
