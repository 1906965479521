import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Vehicle, VehicleStatus } from '../types/vehicle';
import { vehicleService } from '../services/api';

interface ApiError {
  detail: string;
  [key: string]: any;
}

interface VehicleState {
  vehicles: Vehicle[];
  currentVehicle: Vehicle | null;
  loading: boolean;
  error: string | ApiError | null;
  filters: {
    brand?: string;
    vehicle_type?: string;
    price_range?: [number, number];
    transmission?: string;
  };
}

const initialState: VehicleState = {
  vehicles: [],
  currentVehicle: null,
  loading: false,
  error: null,
  filters: {}
};

export const fetchVehicleById = createAsyncThunk<
  Vehicle,
  number,
  { rejectValue: ApiError }
>(
  'vehicles/fetchVehicleById',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await vehicleService.getVehicle(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { detail: 'Failed to fetch vehicle' });
    }
  }
);

export const fetchVehicles = createAsyncThunk<
  Vehicle[],
  Record<string, any>,
  { rejectValue: ApiError }
>(
  'vehicles/fetchVehicles',
  async (filters: Record<string, any> = {}, { rejectWithValue }) => {
    try {
      const response = await vehicleService.getVehicles(filters);
      return response.data.results;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || { detail: 'Failed to fetch vehicles' });
    }
  }
);

const vehicleSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    clearCurrentVehicle(state) {
      state.currentVehicle = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch vehicles list
      .addCase(fetchVehicles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVehicles.fulfilled, (state, action) => {
        state.loading = false;
        state.vehicles = action.payload;
      })
      .addCase(fetchVehicles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || { detail: 'Failed to fetch vehicles' };
      })
      // Fetch single vehicle
      .addCase(fetchVehicleById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVehicleById.fulfilled, (state, action) => {
        state.loading = false;
        state.currentVehicle = action.payload;
        // Also update the vehicle in the list if it exists
        const index = state.vehicles.findIndex(v => v.id === action.payload.id);
        if (index !== -1) {
          state.vehicles[index] = action.payload;
        }
      })
      .addCase(fetchVehicleById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || { detail: 'Failed to fetch vehicle' };
      });
  }
});

export const { setFilters, clearCurrentVehicle } = vehicleSlice.actions;
export default vehicleSlice.reducer;
