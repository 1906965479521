import React from 'react';
import { FaCar } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const Preloader: React.FC = () => {
  const { isDarkMode } = useSelector((state: RootState) => state.theme);

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}>
      <div className="relative">
        {/* Road line animation */}
        <div className="absolute -bottom-12 left-1/2 transform -translate-x-1/2 w-48 h-3">
          <div className="absolute inset-0 animate-road-move">
            <div className={`w-6 h-3 ${isDarkMode ? 'bg-gray-300' : 'bg-gray-800'} rounded-full mx-3`} />
            <div className={`w-6 h-3 ${isDarkMode ? 'bg-gray-300' : 'bg-gray-800'} rounded-full mx-3 ml-12`} />
            <div className={`w-6 h-3 ${isDarkMode ? 'bg-gray-300' : 'bg-gray-800'} rounded-full mx-3 ml-24`} />
          </div>
        </div>

        {/* Car icon with bounce and glow effect */}
        <div className="animate-car-bounce">
          <div className={`text-8xl ${isDarkMode ? 'text-primary-400' : 'text-primary-500'} transform -rotate-12 animate-pulse`}>
            <FaCar />
          </div>
        </div>

        {/* Loading text */}
        <div className={`mt-16 text-xl font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`}>
          Loading...
        </div>
      </div>
    </div>
  );
};

export default Preloader;
