import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import { initializeTheme } from './redux/themeSlice';
import { Toaster, toast } from 'react-hot-toast';
import { FaCar } from 'react-icons/fa';
import { SettingsProvider } from './contexts/SettingsContext';
import { LoadingProvider, useLoading } from './contexts/LoadingContext';
import { NavigationProvider } from './contexts/NavigationContext';
import ThemeProvider from './components/ThemeProvider';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';

// Import components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import WhatsAppButton from './components/WhatsAppButton';
import Preloader from './components/Preloader';
import MobileBottomNav from './components/home/MobileBottomNav';

// Import pages
import HomePage from './pages/HomePage';
import VehicleListPage from './pages/VehicleListPage';
import VehicleDetailPage from './pages/VehicleDetailPage';
import CartPage from './pages/CartPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AdminDashboardPage from './pages/AdminDashboardPage';
import BookingPage from './pages/BookingPage';
// import UserDashboardPage from './pages/UserDashboardPage';
import MyBookingsPage from './pages/MyBookingsPage';
import NotFoundPage from './pages/NotFoundPage';
import CheckoutPage from './pages/CheckoutPage';

// Protected Route wrapper component
const ProtectedRoute: React.FC<{
  children: React.ReactNode;
  requireAdmin?: boolean;
}> = ({ children, requireAdmin = false }) => {
  const { user } = useSelector((state: RootState) => state.auth);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (requireAdmin && !user.is_staff && !user.is_superuser) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

// Auth Route wrapper component (redirects to home if already logged in)
const AuthRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useSelector((state: RootState) => state.auth);

  if (user) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

const RouteChangeHandler: React.FC = () => {
  const location = useLocation();
  const { startLoading, stopLoading } = useLoading();

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    
    const handleRouteChange = () => {
      startLoading();
      timeoutId = setTimeout(() => {
        stopLoading();
      }, 800);
    };

    handleRouteChange();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [location.pathname, startLoading, stopLoading]);

  return null;
};

const AppContent: React.FC = () => {
  const [initialLoading, setInitialLoading] = React.useState(true);
  const { isLoading } = useLoading();

  React.useEffect(() => {
    // Initial app load
    const initialLoadTimer = setTimeout(() => {
      setInitialLoading(false);

      // Welcome notifications after initial load
      const welcomeTimer = setTimeout(() => {
        toast.success(
          'Welcome to Alwasl Rent A Car!',
          {
            duration: 5000,
            position: 'bottom-left',
            icon: '👋',
            style: {
              borderRadius: '10px',
              background: '#333',
              color: '#fff',
            },
          }
        );

        // Delivery notification after a short delay
        const deliveryTimer = setTimeout(() => {
          toast.custom(
            (t) => (
              <div
                className={`${
                  t.visible ? 'animate-enter' : 'animate-leave'
                } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
              >
                <div className="flex-1 w-0 p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                      <FaCar className="h-10 w-10 text-primary-500" />
                    </div>
                    <div className="ml-3 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        Doorstep Delivery Available
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        We deliver cars to your doorstep anywhere in Dubai!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex border-l border-gray-200">
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primary-600 hover:text-primary-500 focus:outline-none"
                  >
                    Close
                  </button>
                </div>
              </div>
            ),
            {
              duration: 6000,
              position: 'bottom-left',
            }
          );
        }, 1000);

        return () => clearTimeout(deliveryTimer);
      }, 500);

      return () => clearTimeout(welcomeTimer);
    }, 2000);

    return () => clearTimeout(initialLoadTimer);
  }, []);

  return (
    <>
      {(initialLoading || isLoading) && <Preloader />}
      <div className="flex flex-col min-h-screen">
        <RouteChangeHandler />
        <Navbar />
        <main className="flex-grow">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/vehicles" element={<VehicleListPage />} />
            <Route path="/vehicles/:id" element={<VehicleDetailPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            
            {/* Auth Routes */}
            <Route
              path="/login"
              element={
                <AuthRoute>
                  <LoginPage />
                </AuthRoute>
              }
            />
            <Route
              path="/register"
              element={
                <AuthRoute>
                  <RegisterPage />
                </AuthRoute>
              }
            />

            {/* Protected Routes */}
            <Route
              path="/bookings"
              element={
                <ProtectedRoute>
                  <MyBookingsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/booking"
              element={
                <ProtectedRoute>
                  <BookingPage />
                </ProtectedRoute>
              }
            />

            {/* Admin Routes */}
            <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoute requireAdmin={true}>
                  <AdminDashboardPage />
                </ProtectedRoute>
              }
            />

            {/* 404 Route */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
        <Footer />
        <WhatsAppButton />
        <MobileBottomNav />
      </div>
      <Toaster position="bottom-left" />
    </>
  );
};

const App: React.FC = () => {
  React.useEffect(() => {
    store.dispatch(initializeTheme());
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SettingsProvider>
          <ThemeProvider>
            <LoadingProvider>
              <NavigationProvider>
                <Router>
                  <AppContent />
                </Router>
              </NavigationProvider>
            </LoadingProvider>
          </ThemeProvider>
        </SettingsProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
