import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, AppDispatch } from '../redux/store';
import { fetchVehicles, setFilters } from '../redux/vehicleSlice';
import { FaFilter, FaSearch, FaMoon, FaSun, FaSuitcase, FaCar } from 'react-icons/fa';
import { BsFillPeopleFill } from 'react-icons/bs';
import Toast from '../components/Toast';

const VehicleListPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { vehicles, loading, error } = useSelector((state: RootState) => state.vehicles);
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [priceRange, setPriceRange] = useState<[number, number]>([0, 1000]);
  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedTransmission, setSelectedTransmission] = useState<string>('');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error' | 'warning' | 'info'>('info');
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    dispatch(fetchVehicles({}));
    document.documentElement.classList.toggle('dark', isDarkMode);
  }, [dispatch, isDarkMode]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleFilter = () => {
    const filters = {
      ...(selectedType && { vehicle_type: selectedType }),
      ...(selectedTransmission && { transmission: selectedTransmission }),
      ...(priceRange[0] > 0 || priceRange[1] < 1000 ? { price_range: priceRange } : {})
    };
    
    if (Object.keys(filters).length === 0) {
      setToastMessage('No filters applied.');
      setToastType('info');
      setShowToast(true);
    } else {
      dispatch(setFilters(filters));
      dispatch(fetchVehicles(filters));
      setShowFilters(false);
    }
  };

  const clearFilters = () => {
    setSelectedType('');
    setSelectedTransmission('');
    setPriceRange([0, 1000]);
    dispatch(setFilters({}));
    dispatch(fetchVehicles({}));
    setToastMessage('Filters cleared.');
    setToastType('success');
    setShowToast(true);
  };

  const filteredVehicles = vehicles.filter(vehicle =>
    vehicle.brand.toLowerCase().includes(searchTerm.toLowerCase()) ||
    vehicle.model.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen dark:bg-gray-900">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8 dark:bg-gray-900">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded dark:bg-red-900 dark:text-red-200">
          {typeof error === 'string' ? error : 'Failed to load vehicles'}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-200">
      <div className="container mx-auto px-4 py-8">
        {showToast && (
          <Toast 
            message={toastMessage} 
            type={toastType} 
            onClose={() => setShowToast(false)} 
          />
        )}
        <div className="mb-8">
          <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
            <div className="relative flex-1 w-full">
              <input
                type="text"
                placeholder="Search vehicles..."
                value={searchTerm}
                onChange={handleSearch}
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent dark:bg-gray-800 dark:text-white dark:border-gray-700"
              />
              <FaSearch className="absolute left-3 top-3 text-gray-400" />
            </div>
            <div className="flex gap-4">
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="flex items-center space-x-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
              >
                <FaFilter />
                <span>Filters</span>
              </button>
              <button
                onClick={() => setIsDarkMode(!isDarkMode)}
                className="flex items-center space-x-2 px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors"
              >
                {isDarkMode ? <FaSun className="text-yellow-300" /> : <FaMoon className="text-gray-300" />}
              </button>
            </div>
          </div>
        </div>

        {showFilters && (
          <div className="mt-4 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Vehicle Type</label>
                <select
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                >
                  <option value="">All Types</option>
                  <option value="SEDAN">Sedan</option>
                  <option value="SUV">SUV</option>
                  <option value="SPORTS">Sports</option>
                  <option value="VAN">Van</option>
                  <option value="TRUCK">Truck</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Transmission</label>
                <select
                  value={selectedTransmission}
                  onChange={(e) => setSelectedTransmission(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                >
                  <option value="">All Types</option>
                  <option value="AUTO">Automatic</option>
                  <option value="MANUAL">Manual</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Price Range</label>
                <div className="mt-1 flex items-center space-x-2">
                  <input
                    type="number"
                    value={priceRange[0]}
                    onChange={(e) => setPriceRange([Number(e.target.value), priceRange[1]])}
                    min="0"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                    placeholder="Min"
                  />
                  <span>-</span>
                  <input
                    type="number"
                    value={priceRange[1]}
                    onChange={(e) => setPriceRange([priceRange[0], Number(e.target.value)])}
                    min={priceRange[0]}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                    placeholder="Max"
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={clearFilters}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
              >
                Clear Filters
              </button>
              <button
                onClick={handleFilter}
                className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
              >
                Apply Filters
              </button>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredVehicles.length === 0 ? (
            <div className="col-span-full text-center py-12">
              <div className="text-gray-500 dark:text-gray-400">No vehicles found</div>
            </div>
          ) : (
            filteredVehicles.map((vehicle) => (
              <div
                key={vehicle.id}
                className="bg-white dark:bg-gray-800 rounded-3xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
              >
                <div className="relative pb-48">
                  <img
                    src={vehicle.image_url || 'https://placehold.co/600x400'}
                    alt={`${vehicle.brand} ${vehicle.model}`}
                    className="absolute h-full w-full object-cover"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = 'https://placehold.co/600x400';
                    }}
                  />
                  <div className="absolute bottom-4 right-4">
                    <div className="bg-white dark:bg-gray-800 text-black dark:text-white px-4 py-2 rounded-lg shadow-md">
                      <span className="text-2xl font-bold text-orange-500">{vehicle.daily_rate} د.إ</span>
                      <span className="text-sm text-gray-600 dark:text-gray-300">/ DAY</span>
                    </div>
                  </div>
                </div>

                <div className="p-6">
                  <h3 className="text-2xl font-bold mb-4 dark:text-white">
                    {vehicle.brand} {vehicle.model}
                  </h3>

                  <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center space-x-2">
                      <BsFillPeopleFill className="text-gray-500 dark:text-gray-400" />
                      <span className="dark:text-gray-300">4 Seat</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <FaSuitcase className="text-gray-500 dark:text-gray-400" />
                      <span className="dark:text-gray-300">4 Bags</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <FaCar className="text-gray-500 dark:text-gray-400" />
                      <span className="dark:text-gray-300">{vehicle.vehicle_type}</span>
                    </div>
                  </div>

                  <div className="flex items-center space-x-4 mb-6">
                    <span className="text-orange-500">Petrol</span>
                    <span className="line-through text-gray-400 dark:text-gray-500">Diesel</span>
                    <span className="line-through text-gray-400 dark:text-gray-500">Electric</span>
                  </div>

                  <button
                    onClick={() => navigate(`/vehicles/${vehicle.id}`)}
                    className="w-full bg-black dark:bg-gray-700 text-white py-2 rounded-lg hover:bg-gray-800 dark:hover:bg-gray-600 transition-colors"
                  >
                    DETAIL
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleListPage;
