import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { bookingService } from '../services/api';

export interface Booking {
  id: number;
  vehicle: {
    id: number;
    brand: string;
    model: string;
    daily_rate: number;
  };
  user: {
    id: number;
    email: string;
    name: string;
  };
  start_date: string;
  end_date: string;
  status: 'pending' | 'confirmed' | 'cancelled';
  total_price: number;
  created_at: string;
}

interface BookingState {
  bookings: Booking[];
  loading: boolean;
  error: string | null;
}

export const fetchUserBookings = createAsyncThunk(
  'bookings/fetchUserBookings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await bookingService.getBookings();
      return response.data as Booking[];
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to fetch bookings');
    }
  }
);

export const createNewBooking = createAsyncThunk(
  'bookings/createBooking',
  async (bookingData: { vehicle_id: number; start_date: string; end_date: string }, { rejectWithValue }) => {
    try {
      const response = await bookingService.createBooking(bookingData);
      return response.data as Booking;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to create booking');
    }
  }
);

export const cancelUserBooking = createAsyncThunk(
  'bookings/cancelBooking',
  async (bookingId: number, { rejectWithValue }) => {
    try {
      await bookingService.cancelBooking(bookingId);
      return bookingId;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to cancel booking');
    }
  }
);

const initialState: BookingState = {
  bookings: [],
  loading: false,
  error: null,
};

const bookingSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    clearBookingError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch bookings
      .addCase(fetchUserBookings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserBookings.fulfilled, (state, action) => {
        state.loading = false;
        state.bookings = action.payload;
      })
      .addCase(fetchUserBookings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Create booking
      .addCase(createNewBooking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewBooking.fulfilled, (state, action) => {
        state.loading = false;
        state.bookings.push(action.payload);
      })
      .addCase(createNewBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Cancel booking
      .addCase(cancelUserBooking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cancelUserBooking.fulfilled, (state, action) => {
        state.loading = false;
        const bookingId = action.payload;
        const booking = state.bookings.find(b => b.id === bookingId);
        if (booking) {
          booking.status = 'cancelled';
        }
      })
      .addCase(cancelUserBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearBookingError } = bookingSlice.actions;
export default bookingSlice.reducer;
