import React, { useEffect, useState } from 'react';
import { Vehicle } from '../types/vehicle';
import { vehicleService } from '../services/api';

const AdminDashboardPage: React.FC = () => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await vehicleService.getVehicles();
        setVehicles(response.data.results); // Update to use results
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicles();
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading vehicles...</p>
      ) : (
        <ul>
          {vehicles.map(vehicle => (
            <li key={vehicle.id}>{vehicle.brand} {vehicle.model}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AdminDashboardPage;
