import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { toggleTheme } from '../../redux/themeSlice';
import { IoCarSportOutline, IoHomeOutline, IoSunnyOutline, IoMoonOutline, IoPersonOutline } from 'react-icons/io5';
import { IoChevronBack } from 'react-icons/io5';
import { useNavigation } from '../../contexts/NavigationContext';

const MobileBottomNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const { isNavHidden, setIsNavHidden } = useNavigation();

  const navItems = [
    { label: 'Home', icon: <IoHomeOutline className="text-2xl" />, path: '/' },
    { label: 'Vehicles', icon: <IoCarSportOutline className="text-2xl" />, path: '/vehicles' },
    { label: 'Profile', icon: <IoPersonOutline className="text-2xl" />, path: '/profile' },
    { 
      label: isDarkMode ? 'Light' : 'Dark', 
      icon: isDarkMode ? <IoSunnyOutline className="text-2xl" /> : <IoMoonOutline className="text-2xl" />,
      action: () => dispatch(toggleTheme())
    },
  ];

  return (
    <div className="fixed bottom-4 md:hidden z-50">
      {/* Main Navigation Bar */}
      <div 
        className={`fixed bottom-4 left-4 right-4 transition-all duration-300 ease-in-out transform ${
          isNavHidden ? 'translate-x-[-100%]' : 'translate-x-0'
        }`}
      >
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg shadow-gray-200/20 dark:shadow-gray-900/30 border border-gray-100 dark:border-gray-700">
          <div className="flex justify-around items-center h-16 px-2">
            {navItems.map((item, index) => (
              <button
                key={index}
                onClick={() => item.action ? item.action() : navigate(item.path)}
                className={`flex flex-col items-center justify-center w-full h-full space-y-1 px-2 py-1 rounded-xl transition-all duration-200 ease-in-out
                  ${location.pathname === item.path 
                    ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900/20' 
                    : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700/30'}`}
              >
                {item.icon}
                <span className="text-xs font-medium">{item.label}</span>
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Toggle Button - Always Visible */}
      <button
        onClick={() => setIsNavHidden(!isNavHidden)}
        className={`fixed bottom-7 ${isNavHidden ? 'left-4' : 'right-0'} bg-white dark:bg-gray-800 p-2 rounded-full shadow-lg shadow-gray-200/20 dark:shadow-gray-900/30 border border-gray-100 dark:border-gray-700 text-gray-600 dark:text-gray-400 transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95 ${
          isNavHidden ? 'rotate-180' : ''
        }`}
      >
        <IoChevronBack className="text-xl" />
      </button>
    </div>
  );
};

export default MobileBottomNav;
