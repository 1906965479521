import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaCar, FaCogs, FaGasPump, FaTachometerAlt, FaUsers, FaCalendarAlt } from 'react-icons/fa';
import Toast from '../components/Toast';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { calculateDurationDiscount, calculateTotalPrice, fetchAdditionalCharges } from '../redux/pricingSlice';
import { addToCart } from '../redux/cartSlice';
import { fetchVehicleById, clearCurrentVehicle } from '../redux/vehicleSlice';
import DatePicker from 'react-datepicker'; // Importing date picker
import 'react-datepicker/dist/react-datepicker.css'; // Importing date picker styles

const VehicleDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [pickupDate, setPickupDate] = useState<Date | null>(null);
  const [returnDate, setReturnDate] = useState<Date | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [selectedTab, setSelectedTab] = useState('overview');
  const [selectedCharges, setSelectedCharges] = useState<number[]>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { currentVehicle, loading, error } = useAppSelector((state) => state.vehicles);
  const { durationDiscount, additionalCharges } = useAppSelector((state) => state.pricing);

  useEffect(() => {
    const loadVehicle = async () => {
      if (!id) return;
      try {
        await dispatch(fetchVehicleById(parseInt(id))).unwrap();
        await dispatch(fetchAdditionalCharges()).unwrap();
      } catch (err) {
        console.error('Error fetching data:', err);
        showToastMessage('Failed to fetch vehicle details', 'error');
      }
    };
  
    loadVehicle();
    
    // Cleanup function to clear current vehicle when leaving the page
    return () => {
      dispatch(clearCurrentVehicle());
    };
  }, [dispatch, id]);

  const showToastMessage = (message: string, type: 'success' | 'error') => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);
  };

  const handleChargeSelection = (chargeId: number, isSelected: boolean) => {
    setSelectedCharges(prev => 
      isSelected 
        ? [...prev, chargeId]
        : prev.filter(id => id !== chargeId)
    );
  };

  const handleDateChange = async () => {
    if (pickupDate && returnDate && currentVehicle) {
      try {
        await dispatch(calculateDurationDiscount({
          vehicle_type: currentVehicle.vehicle_type,
          start_date: pickupDate.toISOString().split('T')[0],
          end_date: returnDate.toISOString().split('T')[0],
          daily_rate: typeof currentVehicle.daily_rate === 'string' ? parseFloat(currentVehicle.daily_rate) : currentVehicle.daily_rate
        }));
      } catch (error) {
        showToastMessage('Error calculating price', 'error');
      }
    }
  };

  const handleReserveClick = async () => {
    if (!pickupDate || !returnDate || !currentVehicle) {
      showToastMessage('Please select pickup and return dates', 'error');
      return;
    }

    if (!currentVehicle.id || !currentVehicle.vehicle_type) {
      console.error('Missing vehicle data:', {
        id: currentVehicle.id,
        vehicle_type: currentVehicle.vehicle_type,
        pickup_location_id: currentVehicle.pickup_location_id
      });
      showToastMessage('Vehicle data is incomplete. Please try again.', 'error');
      return;
    }

    const pickup_location_id = currentVehicle.pickup_location_id || 1;

    try {
      const formattedStartDate = pickupDate.toISOString().split('T')[0];
      const formattedEndDate = returnDate.toISOString().split('T')[0];

      const priceResponse = await dispatch(calculateTotalPrice({
        vehicle_type: currentVehicle.vehicle_type,
        pickup_location_id: pickup_location_id,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        additional_services: selectedCharges.map(id => id.toString()) // Convert to string array
      })).unwrap();

      // Calculate additional charges total
      const calculateAdditionalChargesTotal = (selectedIds: number[]) => {
        if (!additionalCharges || !pickupDate || !returnDate) return 0;
        
        // Calculate number of days
        const start = pickupDate;
        const end = returnDate;
        const days = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));

        return selectedIds.reduce((total, chargeId) => {
          const charge = additionalCharges.find(c => c.id === chargeId);
          if (!charge) return total;
          
          const chargeAmount = parseFloat(charge.amount);
          return total + (charge.is_daily ? chargeAmount * days : chargeAmount);
        }, 0);
      };

      // Calculate final price including additional charges
      const finalPrice = priceResponse.base_price - priceResponse.duration_discount + 
                        priceResponse.seasonal_adjustment + priceResponse.demand_adjustment +
                        calculateAdditionalChargesTotal(selectedCharges);

      dispatch(addToCart({
        vehicle_id: currentVehicle.id,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        daily_rate: typeof currentVehicle.daily_rate === 'string' ? parseFloat(currentVehicle.daily_rate) : currentVehicle.daily_rate,
        vehicle_name: `${currentVehicle.brand} ${currentVehicle.model}`,
        total_price: finalPrice,
        duration_discount: priceResponse.duration_discount || 0,
        additional_charges: selectedCharges.map(chargeId => {
          const charge = additionalCharges?.find(c => c.id === chargeId);
          if (!charge) return null;
          
          const amount = parseFloat(charge.amount);
          const days = charge.is_daily ? Math.ceil(
            (returnDate.getTime() - pickupDate.getTime()) / (1000 * 60 * 60 * 24)
          ) : 1;
          
          return {
            id: chargeId,
            name: charge.name,
            amount: charge.is_daily ? amount * days : amount,
            charge_type: charge.charge_type,
            is_daily: charge.is_daily,
            base_amount: amount
          };
        }).filter(Boolean) as { 
          id: number; 
          name: string; 
          amount: number; 
          charge_type: string; 
          is_daily: boolean; 
          base_amount: number;
        }[]
      }));

      showToastMessage('Added to cart successfully!', 'success');
      navigate('/cart');
    } catch (error) {
      console.error('Price calculation error:', error);
      showToastMessage('Failed to calculate price. Please try again.', 'error');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-500"></div>
      </div>
    );
  }

  if (error || !currentVehicle) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {typeof error === 'string' ? error : error?.detail || 'Vehicle not found'}
        </div>
      </div>
    );
  }

  const features = [
    {
      icon: <FaCar className="text-2xl" />,
      label: 'Model',
      value: `${currentVehicle.brand} ${currentVehicle.model}`
    },
    {
      icon: <FaCogs className="text-2xl" />,
      label: 'Transmission',
      value: currentVehicle.transmission || 'N/A'
    },
    {
      icon: <FaGasPump className="text-2xl" />,
      label: 'Fuel Type',
      value: currentVehicle.fuel_type || 'N/A'
    },
    {
      icon: <FaTachometerAlt className="text-2xl" />,
      label: 'Mileage',
      value: currentVehicle.mileage ? `${currentVehicle.mileage} km` : 'N/A'
    },
    {
      icon: <FaUsers className="text-2xl" />,
      label: 'Seats',
      value: currentVehicle.seats ? `${currentVehicle.seats} People` : 'N/A'
    },
    {
      icon: <FaCalendarAlt className="text-2xl" />,
      label: 'Year',
      value: currentVehicle.year.toString()
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      {showToast && (
        <Toast
          message={toastMessage}
          type={toastType}
          onClose={() => setShowToast(false)}
        />
      )}
      
      {/* Breadcrumb */}
      <div className="text-sm breadcrumbs mb-6">
        <ul className="flex space-x-2 text-gray-600">
          <li><a href="/">Home</a></li>
          <li>/</li>
          <li><a href="/vehicles">Vehicles</a></li>
          <li>/</li>
          <li className="text-primary-500">{currentVehicle.name || `${currentVehicle.brand} ${currentVehicle.model}`}</li>
        </ul>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Left Column */}
        <div className="lg:col-span-2">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            {/* Image Gallery */}
            <div className="relative pb-[60%]">
              <img
                src={currentVehicle.image_url || currentVehicle.image || 'https://via.placeholder.com/800x600?text=No+Image'}
                alt={currentVehicle.name || `${currentVehicle.brand} ${currentVehicle.model}`}
                className="absolute w-full h-full object-cover"
              />
            </div>

            {/* Tabs */}
            <div className="mt-8">
              <div className="border rounded-lg overflow-hidden bg-white">
                {/* Tab Headers */}
                <div className="border-b">
                  <div className="flex space-x-8 p-4">
                    {['overview', 'features', 'specifications'].map((tab) => (
                      <button
                        key={tab}
                        onClick={() => setSelectedTab(tab)}
                        className={`text-lg font-semibold capitalize ${
                          selectedTab === tab 
                            ? 'text-primary-500 border-b-2 border-primary-500' 
                            : 'text-gray-600 hover:text-primary-500'
                        }`}
                      >
                        {tab}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Tab Content */}
                <div className="p-6">
                  {selectedTab === 'overview' && (
                    <div className="space-y-6">
                      <h2 className="text-2xl font-bold mb-4">Vehicle Overview</h2>
                      <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                        {features.map((feature, index) => (
                          <div key={index} className="flex items-center space-x-3 bg-gray-50 p-4 rounded-lg">
                            <div className="text-primary-500">{feature.icon}</div>
                            <div>
                              <p className="text-sm text-gray-500">{feature.label}</p>
                              <p className="font-semibold">{feature.value}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {selectedTab === 'features' && (
                    <div className="space-y-6">
                      <h2 className="text-2xl font-bold mb-4">Vehicle Features</h2>
                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        {currentVehicle.features?.map((feature, index) => (
                          <div key={index} className="flex items-center space-x-2 bg-gray-50 p-3 rounded-lg">
                            <span className="w-2 h-2 bg-primary-500 rounded-full"></span>
                            <span className="text-gray-700">{feature}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {selectedTab === 'specifications' && (
                    <div className="space-y-6">
                      <h2 className="text-2xl font-bold mb-4">Vehicle Specifications</h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="space-y-4">
                          <div className="flex justify-between py-2 border-b">
                            <span className="font-medium text-gray-600">Transmission</span>
                            <span>{currentVehicle.transmission || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between py-2 border-b">
                            <span className="font-medium text-gray-600">Fuel Type</span>
                            <span>{currentVehicle.fuel_type || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between py-2 border-b">
                            <span className="font-medium text-gray-600">Mileage</span>
                            <span>{currentVehicle.mileage ? `${currentVehicle.mileage} km` : 'N/A'}</span>
                          </div>
                        </div>
                        <div className="space-y-4">
                          <div className="flex justify-between py-2 border-b">
                            <span className="font-medium text-gray-600">Seating Capacity</span>
                            <span>{currentVehicle.seats ? `${currentVehicle.seats} persons` : 'N/A'}</span>
                          </div>
                          <div className="flex justify-between py-2 border-b">
                            <span className="font-medium text-gray-600">Year</span>
                            <span>{currentVehicle.year}</span>
                          </div>
                          <div className="flex justify-between py-2 border-b">
                            <span className="font-medium text-gray-600">Status</span>
                            <span className={currentVehicle.is_available ? 'text-green-500' : 'text-red-500'}>
                              {currentVehicle.is_available ? 'Available' : 'Not Available'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column - Booking Card */}
        <div className="lg:col-span-1">
          <div className="bg-white rounded-lg shadow-lg p-6 sticky top-6">
            <div className="text-center mb-6">
              <h2 className="text-3xl font-bold text-primary-500">د.إ{currentVehicle.price_per_day || currentVehicle.daily_rate}</h2>
              <p className="text-gray-600">per day</p>
            </div>

            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Pick-up Date</label>
                  <DatePicker
                    selected={pickupDate}
                    onChange={(date) => {
                      setPickupDate(date);
                      handleDateChange(); // Call to update total on date selection
                    }}
                    minDate={new Date()}
                    className="w-full border rounded-lg p-2"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Return Date</label>
                  <DatePicker
                    selected={returnDate}
                    onChange={(date) => {
                      setReturnDate(date);
                      handleDateChange(); // Call to update total on date selection
                    }}
                    minDate={pickupDate || new Date()}
                    className="w-full border rounded-lg p-2"
                  />
                </div>
              </div>

              {pickupDate && returnDate && currentVehicle && durationDiscount && (
                <div className="space-y-2 mt-4">
                  <div className="flex justify-between text-sm">
                    <span>Base Price:</span>
                    <span>د.إ{durationDiscount.base_price.toFixed(2)}</span>
                  </div>
                  {durationDiscount.discount_amount > 0 && (
                    <div className="flex justify-between text-sm text-green-600">
                      <span>Duration Discount ({durationDiscount.discount_percentage}%):</span>
                      <span>-د.إ{durationDiscount.discount_amount.toFixed(2)}</span>
                    </div>
                  )}
                  
                  {/* Selected Additional Charges */}
                  {selectedCharges.length > 0 && additionalCharges && (
                        <div className="space-y-2">
                          {selectedCharges.map(chargeId => {
                            const charge = additionalCharges.find((c) => c?.id === chargeId);
                            if (!charge) return null;
                            
                            const amount = parseFloat(charge.amount);
                            const days = charge.is_daily ? Math.ceil(
                              (returnDate.getTime() - pickupDate.getTime()) / (1000 * 60 * 60 * 24)
                            ) : 1;
                            
                            return (
                              <div key={chargeId} className="flex justify-between text-sm">
                                <span>{charge.name}:</span>
                                <span>
                                  د.إ{(amount * days).toFixed(2)}
                                  {charge.is_daily && <span className="text-xs text-gray-500 ml-1">
                                    (د.إ{amount.toFixed(2)} × {days} days)
                                  </span>}
                                </span>
                              </div>
                            );
                          }).filter(Boolean)}
                        </div>
                      )}
                  <div className="flex justify-between font-bold mt-4 pt-4 border-t">
                    <span>Total:</span>
                    <span>د.إ{(
                      durationDiscount.final_price + selectedCharges.reduce((total, chargeId) => {
                        const charge = additionalCharges?.find((c) => c?.id === chargeId);
                        if (!charge) return total;
                        
                        const amount = parseFloat(charge.amount);
                        const days = charge.is_daily ? Math.ceil(
                          (returnDate.getTime() - pickupDate.getTime()) / (1000 * 60 * 60 * 24)
                        ) : 1;
                        
                        return total + (amount * days);
                      }, 0)
                    ).toFixed(2)}</span>
                  </div>
                  <div className="text-sm text-gray-500 text-center">
                    د.إ{durationDiscount.price_per_day.toFixed(2)} per day
                  </div>
                </div>
              )}
              {/* Additional Charges Section */}
              {additionalCharges && additionalCharges.length > 0 && (
                <div className="mt-4 border-t pt-4">
                  <h4 className="text-sm font-semibold mb-2">Additional Services Available:</h4>
                  <div className="space-y-2">
                    {additionalCharges.map((charge) => (
                      <div key={charge.id} className="flex items-center justify-between text-sm">
                        <div className="flex items-center flex-1">
                          <input
                            type="checkbox"
                            id={`charge-${charge.id}`}
                            className="mr-2 rounded text-primary-500 focus:ring-primary-500"
                            checked={selectedCharges.includes(charge.id)}
                            onChange={(e) => handleChargeSelection(charge.id, e.target.checked)}
                          />
                          <label htmlFor={`charge-${charge.id}`} className="cursor-pointer flex-1">
                            <div className="font-medium">{charge.name}</div>
                            <div className="text-xs text-gray-500">Type: {charge.charge_type}</div>
                            <div className="text-xs text-gray-500">
                              {charge.is_daily ? 'Charged daily' : 'One-time charge'}
                            </div>
                          </label>
                        </div>
                        <div className="text-right">
                          <span className="font-medium">د.إ{parseFloat(charge.amount).toFixed(2)}</span>
                          {charge.is_daily && <div className="text-xs text-gray-500">per day</div>}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <button 
                onClick={handleReserveClick}
                className={`w-full py-3 rounded-lg font-semibold transition duration-300 ${
                  (currentVehicle.availability || currentVehicle.is_available)
                    ? 'bg-primary-500 text-white hover:bg-primary-600' 
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
                disabled={!(currentVehicle.availability || currentVehicle.is_available) || !pickupDate || !returnDate}
              >
                {(currentVehicle.availability || currentVehicle.is_available) ? 'Reserve Now' : 'Not Available'}
              </button>

              <div className="text-center text-sm text-gray-500">
                * Terms and conditions apply
              </div>
            </div>

            {/* Contact Info */}
            <div className="mt-6 pt-6 border-t">
              <h3 className="text-lg font-semibold mb-4">Need Help?</h3>
              <div className="space-y-2">
                <p className="flex items-center space-x-2">
                  <span className="font-medium">Email:</span>
                  <a href="mailto:info@alwalscar.com" className="text-primary-500">info@alwalscar.com</a>
                </p>
                <p className="flex items-center space-x-2">
                  <span className="font-medium">Phone:</span>
                  <a href="tel:+97143325599" className="text-primary-500">+971 43325599</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetailPage;
