import React from 'react';
import { FaFacebook, FaInstagram, FaPhone, FaEnvelope, FaMapMarkerAlt, FaWhatsapp } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useSettings } from '../contexts/SettingsContext';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const { isDarkMode } = useSelector((state: RootState) => state.theme);
  const { settings, loading } = useSettings();

  if (loading || !settings) {
    return null; // or a loading spinner
  }

  return (
    <footer className={`${isDarkMode ? 'bg-gray-900 text-gray-300' : 'bg-gray-100 text-gray-700'} py-12`}>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Company Info */}
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              {settings.company_logo && (
                <img 
                  src={settings.company_logo} 
                  alt={settings.company_name} 
                  className="h-12 w-auto" 
                />
              )}
              <h3 className="text-2xl font-bold text-primary-500">{settings.company_name}</h3>
            </div>
            <p className="text-sm">
              {settings.footer_description}
            </p>
            <div className="flex space-x-4">
              {settings.facebook_url && (
                <a 
                  href={settings.facebook_url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="hover:text-primary-500 transition-colors"
                >
                  <FaFacebook size={24} />
                </a>
              )}
              {settings.instagram_url && (
                <a 
                  href={settings.instagram_url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="hover:text-primary-500 transition-colors"
                >
                  <FaInstagram size={24} />
                </a>
              )}
            </div>
          </div>

          {/* Contact Info */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Contact Us</h3>
            <div className="space-y-2">
              <p className="flex items-center gap-2">
                <FaPhone className="text-primary-500" />
                <a href={`tel:${settings.company_phone}`} className="hover:text-primary-500 transition-colors">
                  {settings.company_phone}
                </a>
              </p>
              {settings.whatsapp_number && (
                <p className="flex items-center gap-2">
                  <FaWhatsapp className="text-primary-500" />
                  <a 
                    href={`https://wa.me/${settings.whatsapp_number.replace(/[^0-9]/g, '')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-primary-500 transition-colors"
                  >
                    {settings.whatsapp_number}
                  </a>
                </p>
              )}
              <p className="flex items-center gap-2">
                <FaEnvelope className="text-primary-500" />
                <a 
                  href={`mailto:${settings.company_email}`}
                  className="hover:text-primary-500 transition-colors"
                >
                  {settings.company_email}
                </a>
              </p>
              <p className="flex items-center gap-2">
                <FaMapMarkerAlt className="text-primary-500" />
                <span>{settings.company_address}</span>
              </p>
            </div>
          </div>

          {/* Quick Links */}
          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/vehicles" className="hover:text-primary-500 transition-colors">
                  Our Fleet
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-primary-500 transition-colors">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-primary-500 transition-colors">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-700">
          <p className="text-center text-sm">
            {settings.footer_copyright}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
