import axios from 'axios';
import { Vehicle } from '../types/vehicle';

// Use the environment variable, fallback to development URL if not set
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/api/v1';

// Log the API URL being used (helpful for debugging)
console.log('API Base URL:', API_BASE_URL);

export interface User {
  id: number;
  username: string;
  email: string;
  role?: string;
  is_staff?: boolean;
  is_superuser?: boolean;
}

export interface TokenPair {
  access: string;
  refresh: string;
}

export interface LoginResponse {
  access: string;
  refresh: string;
  user: User;
}

export interface RegisterResponse {
  user: User;
  access: string;
  refresh: string;
}

export interface RefreshResponse {
  access: string;
}

export interface VehicleApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Vehicle[];
}

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    console.log('JWT Token being sent:', token); // Log the token for debugging
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error is due to an expired token and we haven't tried refreshing yet
    if (error.response?.status === 401 && !originalRequest._retry && originalRequest.url !== '/token/refresh/') {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        console.log('Refresh Token being used:', refreshToken); // Log the refresh token for debugging
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }

        const response = await authService.refreshToken(refreshToken);
        const { access } = response.data;

        // Update the tokens
        localStorage.setItem('authToken', access);

        // Update the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${access}`;
        return api(originalRequest);
      } catch (refreshError) {
        // If refresh token fails, clear tokens and redirect to login
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export interface LoginCredentials {
  username: string;
  password: string;
}

export interface RegisterData {
  username: string;
  email: string;
  password: string;
  first_name?: string;
  last_name?: string;
}

export const vehicleService = {
  getVehicles: (filters = {}) => {
    console.log('Fetching vehicles with filters:', filters); // Debug log
    return api.get<VehicleApiResponse>('/vehicles/', { params: filters });
  },
  getVehicle: (id: number) => api.get<Vehicle>(`/vehicles/${id}/`),
  createVehicle: (data: Partial<Vehicle>) => api.post<Vehicle>('/vehicles/', data),
  updateVehicle: (id: number, data: Partial<Vehicle>) => api.patch<Vehicle>(`/vehicles/${id}/`, data),
  deleteVehicle: (id: number) => api.delete(`/vehicles/${id}/`)
};

export const bookingService = {
  getBookings: () => {
    return api.get('/bookings/');
  },
  createBooking: (data: { vehicle_id: number; start_date: string; end_date: string }) => {
    return api.post('/bookings/', data);
  },
  getBooking: (id: number) => {
    return api.get(`/bookings/${id}/`);
  },
  updateBooking: (id: number, data: Partial<{ status: string }>) => {
    return api.patch(`/bookings/${id}/`, data);
  },
  cancelBooking: (id: number) => {
    return api.delete(`/bookings/${id}/`);
  }
};

export const authService = {
  login: async (credentials: LoginCredentials) => {
    const response = await api.post<LoginResponse>('/token/', credentials);
    if (response.data.access) {
      localStorage.setItem('authToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
    }
    return response;
  },

  register: async (userData: RegisterData) => {
    const response = await api.post<RegisterResponse>('/users/', userData);
    if (response.data.access) {
      localStorage.setItem('authToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
    }
    return response;
  },

  refreshToken: async (refresh: string) => {
    return api.post<RefreshResponse>('/token/refresh/', { refresh });
  },

  logout: () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    return Promise.resolve();
  },

  getCurrentUser: () => {
    return api.get<User>('/users/me/');
  }
};

export default api;
