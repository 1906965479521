import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { useSettings } from '../contexts/SettingsContext';
import { useNavigation } from '../contexts/NavigationContext';

const WhatsAppButton: React.FC = () => {
  const { settings } = useSettings();
  const { isNavHidden } = useNavigation();
  const whatsappNumber = settings?.whatsapp_number || '+971501234567';

  const handleWhatsAppClick = () => {
    const message = encodeURIComponent('Hello! I would like to rent a car.');
    window.open(`https://wa.me/${whatsappNumber}?text=${message}`, '_blank');
  };

  return (
    <div className={`fixed right-6 z-[60] transition-all duration-300 ease-in-out transform md:bottom-8 ${
      isNavHidden ? 'bottom-8' : 'bottom-24'
    }`}>
      <button
        onClick={handleWhatsAppClick}
        className="group relative flex items-center"
      >
        {/* Tooltip */}
        <span className="absolute right-full mr-4 -translate-y-1/2 top-1/2 whitespace-nowrap rounded-lg bg-black px-4 py-2 text-sm text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 shadow-lg">
          Chat with us on WhatsApp
          {/* Tooltip arrow */}
          <span className="absolute right-[-8px] top-1/2 -translate-y-1/2 border-[6px] border-transparent border-l-black"></span>
        </span>

        {/* Button with 3D effect */}
        <div className="relative transform transition-all duration-300 hover:scale-110 active:scale-95">
          {/* Shadow element for 3D effect */}
          <div className="absolute -inset-2 bg-gradient-to-r from-green-600 to-green-400 rounded-full blur-lg opacity-70 group-hover:opacity-100 animate-pulse transition-opacity"></div>
          
          {/* Main button */}
          <div className="relative bg-gradient-to-r from-green-500 to-green-400 text-white p-4 rounded-full shadow-[0_8px_30px_rgb(0,0,0,0.12)] hover:shadow-[0_8px_30px_rgba(0,255,0,0.2)] transition-all">
            <FaWhatsapp className="w-7 h-7 md:w-8 md:h-8" />
          </div>
        </div>
      </button>
    </div>
  );
};

export default WhatsAppButton;
