import api from './api';

export interface DurationDiscountRequest {
  vehicle_type: string;
  start_date: string;
  end_date: string;
  daily_rate: number;
}

export interface DurationDiscountResponse {
  rental_days: number;
  base_price: number;
  discount_percentage: number;
  discount_amount: number;
  final_price: number;
  price_per_day: number;
}

export interface BasePricing {
  id: number;
  vehicle_type: string;
  base_rate: number;
  currency: string;
}

export interface SeasonalPricing {
  id: number;
  season_name: string;
  start_date: string;
  end_date: string;
  multiplier: number;
}

export interface DemandPricing {
  id: number;
  demand_factor: number;
  threshold: number;
  multiplier: number;
}

export interface AdditionalCharge {
  id: number;
  name: string;
  charge_type: 'INSURANCE' | string;
  amount: string;
  is_daily: boolean;
  created_at: string;
  updated_at: string;
}

export interface PromotionalDiscount {
  id: number;
  code: string;
  description: string;
  discount_percentage: number;
  valid_from: string;
  valid_until: string;
  is_active: boolean;
}

export const pricingService = {
  calculateDurationDiscount: async (data: DurationDiscountRequest): Promise<DurationDiscountResponse> => {
    const response = await api.post('/pricing/calculator/calculate_duration_discount/', data);
    return response.data;
  },

  calculateTotalPrice: async (data: {
    vehicle_type: string;
    pickup_location_id: number;
    start_date: string;
    end_date: string;
    additional_services?: string[];
    promo_code?: string;
  }) => {
    const response = await api.post('/pricing/calculator/calculate_price/', data);
    return response.data;
  },

  getBasePricing: async (): Promise<BasePricing[]> => {
    const response = await api.get('/pricing/base-pricing/');
    return response.data;
  },

  getSeasonalPricing: async (): Promise<SeasonalPricing[]> => {
    const response = await api.get('/pricing/seasonal-pricing/');
    return response.data;
  },

  getDemandPricing: async (): Promise<DemandPricing[]> => {
    const response = await api.get('/pricing/demand-pricing/');
    return response.data;
  },

  getAdditionalCharges: async (): Promise<AdditionalCharge[]> => {
    const token = localStorage.getItem('access_token'); // Assuming the token is stored in localStorage
    console.log('JWT Token:', token); // Log the token for debugging
    const response = await api.get('/pricing/additional-charges/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.results || [];
  },

  getPromotionalDiscounts: async (): Promise<PromotionalDiscount[]> => {
    const response = await api.get('/pricing/promotional-discounts/');
    return response.data;
  },

  getDurationDiscounts: async (): Promise<any[]> => {
    const response = await api.get('/pricing/duration-discounts/');
    return response.data;
  }
};

export default pricingService;
