import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import { removeFromCart } from '../redux/cartSlice'; // Removed clearCart import
import { FaTrash, FaCar, FaCalendarAlt, FaClock, FaTags, FaArrowLeft, FaShoppingCart } from 'react-icons/fa';
import { format } from 'date-fns';

const CartPage: React.FC = () => {
  const cart = useAppSelector((state) => state.cart.items);
  const { isDarkMode } = useAppSelector((state) => state.theme);
  const { user, token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleRemoveItem = (vehicleId: number) => {
    dispatch(removeFromCart(vehicleId));
  };

  const handleCheckout = () => {
    if (!token || !user) {
      // Redirect to login if not authenticated
      navigate('/login', { state: { from: '/cart' } });
      return;
    }
    // Navigate to checkout if authenticated
    navigate('/checkout');
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.total_price, 0);
  };

  if (cart.length === 0) {
    return (
      <div className={`min-h-screen flex flex-col items-center justify-center p-4 ${
        isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-50 text-gray-900'
      }`}>
        <FaShoppingCart className="text-6xl mb-4 text-gray-400" />
        <h1 className="text-2xl font-bold mb-4">Your Cart is Empty</h1>
        <p className="text-gray-500 mb-8">Start browsing our amazing vehicles!</p>
        <button
          onClick={() => navigate('/vehicles')}
          className="flex items-center gap-2 bg-primary-500 text-white px-6 py-3 rounded-lg hover:bg-primary-600 transition-colors"
        >
          <FaArrowLeft />
          Browse Vehicles
        </button>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-50 text-gray-900'}`}>
      <div className="container mx-auto px-4 py-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold flex items-center gap-3">
            <FaShoppingCart className="text-primary-500" />
            Your Cart
          </h1>
          <button
            onClick={() => navigate('/vehicles')}
            className="flex items-center gap-2 text-primary-500 hover:text-primary-600 transition-colors"
          >
            <FaArrowLeft />
            Continue Shopping
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Cart Items */}
          <div className="lg:col-span-2 space-y-4">
            {cart.map((item) => (
              <div
                key={item.vehicle_id}
                className={`rounded-xl overflow-hidden shadow-lg transition-transform hover:-translate-y-1 ${
                  isDarkMode ? 'bg-gray-800' : 'bg-white'
                }`}
              >
                <div className="p-6">
                  <div className="flex justify-between items-start">
                    <div>
                      <h2 className="text-2xl font-bold mb-2">{item.vehicle_name}</h2>
                      <div className="space-y-2">
                        <div className="flex items-center gap-2 text-gray-500">
                          <FaCalendarAlt />
                          <span>
                            {format(new Date(item.start_date), 'MMM dd, yyyy')} - {format(new Date(item.end_date), 'MMM dd, yyyy')}
                          </span>
                        </div>
                        <div className="flex items-center gap-2 text-gray-500">
                          <FaClock />
                          <span>Daily Rate: د.إ{item.daily_rate}</span>
                        </div>
                        {item.duration_discount > 0 && (
                          <div className="flex items-center gap-2 text-green-500">
                            <FaTags />
                            <span>Discount: د.إ{item.duration_discount.toFixed(2)}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      onClick={() => handleRemoveItem(item.vehicle_id)}
                      className="text-red-500 hover:text-red-600 transition-colors p-2"
                      aria-label="Remove item"
                    >
                      <FaTrash size={20} />
                    </button>
                  </div>
                  <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
                    <div className="flex justify-between items-center">
                      <span className="text-lg font-semibold">Total</span>
                      <span className="text-2xl font-bold text-primary-500">
                        د.إ{item.total_price.toFixed(2)}
                      </span>
                    </div>
                  </div>
                  {/* Display Additional Charges */}
                  {item.additional_charges && item.additional_charges.length > 0 && (
                    <div className="mt-4">
                      <h4 className="text-sm font-semibold">Additional Services:</h4>
                      <ul className="list-disc list-inside">
                        {item.additional_charges.map((charge) => (
                          <li key={charge.id} className="text-gray-600">
                            {charge.name}: د.إ{charge.amount.toFixed(2)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Order Summary */}
          <div className="lg:col-span-1">
            <div className={`rounded-xl shadow-lg p-6 sticky top-4 ${
              isDarkMode ? 'bg-gray-800' : 'bg-white'
            }`}>
              <h2 className="text-xl font-bold mb-4">Order Summary</h2>
              <div className="space-y-3">
                {cart.map((item) => (
                  <div key={item.vehicle_id} className="flex justify-between text-sm">
                    <span>{item.vehicle_name}</span>
                    <span>د.إ{item.total_price.toFixed(2)}</span>
                  </div>
                ))}
                <div className="border-t border-gray-200 dark:border-gray-700 pt-3 mt-3">
                  <div className="flex justify-between items-center text-lg font-bold">
                    <span>Total</span>
                    <span className="text-primary-500">د.إ{calculateTotal().toFixed(2)}</span>
                  </div>
                </div>
              </div>
              <button
                onClick={handleCheckout}
                className="w-full mt-6 bg-primary-500 text-white py-3 px-4 rounded-lg font-medium hover:bg-primary-600 transition-colors flex items-center justify-center gap-2"
              >
                <FaCar />
                Proceed to Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
