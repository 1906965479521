import React from 'react';

interface AvatarProps {
  username: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
}

const Avatar: React.FC<AvatarProps> = ({ username, size = 'md', className = '' }) => {
  const initials = username
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);

  const sizeClasses = {
    sm: 'w-8 h-8 text-sm',
    md: 'w-10 h-10 text-base',
    lg: 'w-12 h-12 text-lg'
  };

  return (
    <div
      className={`
        ${sizeClasses[size]}
        flex items-center justify-center
        rounded-full bg-primary-500
        text-white font-semibold
        ${className}
      `}
    >
      {initials}
    </div>
  );
};

export default Avatar;
