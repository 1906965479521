import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { logout } from '../redux/authSlice';
import { toggleTheme } from '../redux/themeSlice';
import { Menu, Transition } from '@headlessui/react';
import { FaPhone, FaWhatsapp, FaSun, FaMoon, FaUser, FaCalendarAlt, FaCog, FaSignOutAlt, FaShoppingCart, FaBars, FaTimes } from 'react-icons/fa';
import { useSettings } from '../contexts/SettingsContext';
import Avatar from './Avatar';

interface MenuItemProps {
  active: boolean;
}

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, token } = useSelector((state: RootState) => state.auth);
  const { isDarkMode } = useSelector((state: RootState) => state.theme);
  const { settings } = useSettings();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isAuthenticated = !!token && !!user;

  console.log('User:', user); // Log user state for debugging
  console.log('Token:', token); // Log token for debugging

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };

  const renderUserDropdown = () => (
    <Menu as="div" className="relative">
      <Menu.Button className="flex items-center gap-2 hover:opacity-80 transition-opacity">
        <Avatar username={user?.username || ''} size="sm" />
      </Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }: MenuItemProps) => (
                <Link
                  to="/profile"
                  className={`flex items-center gap-2 px-4 py-2 text-sm ${
                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                  }`}
                >
                  <FaUser />
                  Profile
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }: MenuItemProps) => (
                <Link
                  to="/bookings"
                  className={`flex items-center gap-2 px-4 py-2 text-sm ${
                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                  }`}
                >
                  <FaCalendarAlt />
                  My Bookings
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }: MenuItemProps) => (
                <Link
                  to="/settings"
                  className={`flex items-center gap-2 px-4 py-2 text-sm ${
                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                  }`}
                >
                  <FaCog />
                  Settings
                </Link>
              )}
            </Menu.Item>
            <div className="border-t border-gray-200 dark:border-gray-600 my-1"></div>
            <Menu.Item>
              {({ active }: MenuItemProps) => (
                <button
                  onClick={handleLogout}
                  className={`flex items-center gap-2 px-4 py-2 text-sm w-full text-left text-red-600 dark:text-red-400 ${
                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                  }`}
                >
                  <FaSignOutAlt />
                  Logout
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );

  return (
    <>
      {/* Top Bar */}
      <div className={`hidden md:block ${
        isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-600'
      }`}>
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center h-10">
            <div className="flex items-center space-x-6 text-sm">
              {settings?.company_phone && (
                <a 
                  href={`tel:${settings.company_phone}`} 
                  className="flex items-center space-x-2 hover:text-primary-500 transition-colors"
                >
                  <FaPhone className="text-primary-500" />
                  <span>{settings.company_phone}</span>
                </a>
              )}
              {settings?.whatsapp_number && (
                <a 
                  href={`https://wa.me/${settings.whatsapp_number.replace(/[^0-9]/g, '')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center space-x-2 hover:text-primary-500 transition-colors"
                >
                  <FaWhatsapp className="text-primary-500" />
                  <span>{settings.whatsapp_number}</span>
                </a>
              )}
            </div>
            <div className="flex items-center space-x-4">
              {/* Cart Icon */}
              <div className="relative ml-4">
                <button
                  onClick={() => navigate('/cart')}
                  className={`p-2 rounded-full transition-colors flex items-center justify-center ${
                    isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
                  }`}
                  aria-label="Shopping Cart"
                >
                  <FaShoppingCart className={`text-xl ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`} />
                  {cartItems.length > 0 && (
                    <span className="absolute -top-1 -right-1 bg-primary-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                      {cartItems.length}
                    </span>
                  )}
                </button>
              </div>

              {/* Theme Toggle */}
              <button 
                onClick={handleThemeToggle} 
                className={`p-1 rounded-full transition-colors ${
                  isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
                }`}
                aria-label="Toggle Theme"
              >
                {isDarkMode ? (
                  <FaSun className="text-yellow-400" />
                ) : (
                  <FaMoon className="text-gray-600" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Navbar */}
      <nav className={`fixed top-0 md:top-10 left-0 right-0 z-50 transition-colors duration-200 ${
        isDarkMode 
          ? 'bg-gray-900 text-white border-b border-gray-800' 
          : 'bg-white text-gray-800 border-b border-gray-200'
      }`}>
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center h-16 md:h-20">
            {/* Logo and brand */}
            <Link to="/" className="flex items-center space-x-3 hover:opacity-80 transition-opacity">
              {settings?.company_logo ? (
                <img src={settings.company_logo} alt={settings.company_name} className="h-10 md:h-20 w-auto" />
              ) : (
                <img src="/logo.png" alt="Car Rental" className="h-10 md:h-18 w-auto" />
              )}
              <span className="font-bold text-xl md:text-2xl">
                {/* {settings?.company_name || 'Car Rental'} */}
              </span>
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <Link 
                to="/vehicles" 
                className="text-lg font-medium hover:text-primary-500 transition-colors"
              >
                Vehicles
              </Link>
              {isAuthenticated ? (
                renderUserDropdown()
              ) : (
                <div className="flex items-center space-x-6">
                  <Link 
                    to="/login" 
                    className="text-lg font-medium hover:text-primary-500 transition-colors"
                  >
                    Login
                  </Link>
                  <Link 
                    to="/register" 
                    className="px-4 py-2 rounded-full bg-primary-500 text-white hover:bg-primary-600 transition-colors"
                  >
                    Register
                  </Link>
                </div>
              )}
            </div>

            {/* Mobile menu button */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile menu */}
      <div className={`${
        isMenuOpen ? 'fixed' : 'hidden'
      } inset-0 z-40 md:hidden ${
        isDarkMode ? 'bg-gray-900' : 'bg-white'
      }`}>
        <div className="pt-20 pb-6 px-4 space-y-4">
          <Link
            to="/vehicles"
            className="block text-lg font-medium hover:text-primary-500 transition-colors"
            onClick={() => setIsMenuOpen(false)}
          >
            Vehicles
          </Link>
          {isAuthenticated ? (
            <>
              <Link
                to="/profile"
                className="block text-lg font-medium hover:text-primary-500 transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                <div className="flex items-center gap-2">
                  <FaUser />
                  Profile
                </div>
              </Link>
              <Link
                to="/bookings"
                className="block text-lg font-medium hover:text-primary-500 transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                <div className="flex items-center gap-2">
                  <FaCalendarAlt />
                  My Bookings
                </div>
              </Link>
              <Link
                to="/settings"
                className="block text-lg font-medium hover:text-primary-500 transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                <div className="flex items-center gap-2">
                  <FaCog />
                  Settings
                </div>
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  setIsMenuOpen(false);
                }}
                className="block w-full text-left text-lg font-medium text-red-600 hover:text-red-700 transition-colors"
              >
                <div className="flex items-center gap-2">
                  <FaSignOutAlt />
                  Logout
                </div>
              </button>
            </>
          ) : (
            <>
              <Link
                to="/login"
                className="block text-lg font-medium hover:text-primary-500 transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                Login
              </Link>
              <Link
                to="/register"
                className="block text-lg font-medium bg-primary-500 text-white px-4 py-2 rounded-full hover:bg-primary-600 transition-colors text-center"
                onClick={() => setIsMenuOpen(false)}
              >
                Register
              </Link>
            </>
          )}
        </div>
      </div>

      {/* Spacer for fixed navbar */}
      <div className="h-16 md:h-30"></div>
    </>
  );
};

export default Navbar;
