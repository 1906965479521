import { configureStore } from '@reduxjs/toolkit';
import { 
  persistStore, 
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER 
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import vehicleReducer from './vehicleSlice';
import authReducer from './authSlice';
import bookingReducer from './bookingSlice';
import themeReducer from './themeSlice';
import pricingReducer from './pricingSlice';
import cartReducer from './cartSlice';

// Persist config for auth
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['user', 'token', 'isAuthenticated']
};

// Persist config for theme
const themePersistConfig = {
  key: 'theme',
  storage
};

// Persist config for vehicles
const vehiclePersistConfig = {
  key: 'vehicles',
  storage,
  whitelist: ['currentVehicle']
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedThemeReducer = persistReducer(themePersistConfig, themeReducer);
const persistedVehicleReducer = persistReducer(vehiclePersistConfig, vehicleReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    theme: persistedThemeReducer,
    vehicles: persistedVehicleReducer,
    bookings: bookingReducer,
    pricing: pricingReducer,
    cart: cartReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
