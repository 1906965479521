import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { 
  FaSearch, 
  FaMapMarkerAlt, 
  FaCalendarAlt,
  FaExchangeAlt,
  FaChevronDown
} from 'react-icons/fa';

interface Location {
  id: string;
  name: string;
  address: string;
  type: 'airport' | 'city' | 'landmark';
}

const LOCATIONS: Location[] = [
  { 
    id: '1', 
    name: 'Dubai International Airport', 
    address: 'Dubai, UAE', 
    type: 'airport' 
  },
  { 
    id: '2', 
    name: 'Dubai Marina', 
    address: 'Dubai Marina, Dubai', 
    type: 'landmark' 
  },
  { 
    id: '3', 
    name: 'Downtown Dubai', 
    address: 'Downtown, Dubai', 
    type: 'city' 
  },
  { 
    id: '4', 
    name: 'Palm Jumeirah', 
    address: 'Palm Jumeirah, Dubai', 
    type: 'landmark' 
  }
];

const SearchBar: React.FC = () => {
  const navigate = useNavigate();
  const [pickupLocation, setPickupLocation] = useState('');
  const [dropoffLocation, setDropoffLocation] = useState('');
  const [pickupDate, setPickupDate] = useState<Date | null>(null);
  const [dropoffDate, setDropoffDate] = useState<Date | null>(null);
  const [locationSuggestions, setLocationSuggestions] = useState<Location[]>([]);
  const [activeLocationInput, setActiveLocationInput] = useState<'pickup' | 'dropoff' | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const locationInputRef = useRef<HTMLInputElement>(null);

  const handleLocationChange = (value: string, type: 'pickup' | 'dropoff') => {
    const inputValue = value.toLowerCase();
    const suggestions = LOCATIONS.filter(location => 
      location.name.toLowerCase().includes(inputValue) || 
      location.address.toLowerCase().includes(inputValue)
    );

    if (type === 'pickup') {
      setPickupLocation(value);
      setActiveLocationInput('pickup');
    } else {
      setDropoffLocation(value);
      setActiveLocationInput('dropoff');
    }

    setLocationSuggestions(suggestions);
  };

  const handleLocationSelect = (location: Location) => {
    if (activeLocationInput === 'pickup') {
      setPickupLocation(location.name);
    } else {
      setDropoffLocation(location.name);
    }
    setLocationSuggestions([]);
    setActiveLocationInput(null);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    navigate('/vehicles', {
      state: {
        pickupLocation,
        dropoffLocation,
        pickupDate,
        dropoffDate
      }
    });
  };

  const swapLocations = () => {
    const tempPickup = pickupLocation;
    setPickupLocation(dropoffLocation);
    setDropoffLocation(tempPickup);
  };

  return (
    <div className="w-full bg-white dark:bg-gray-800 shadow-2xl rounded-2xl p-3 lg:p-6">
      <form onSubmit={handleSearch} className="w-full">
        {/* Mobile Collapsed View */}
        <div className="lg:hidden">
          <div className="flex items-center justify-between mb-3" onClick={() => setIsExpanded(!isExpanded)}>
            <div className="flex-1">
              <div className="font-medium text-gray-900 dark:text-gray-100">
                {pickupLocation || 'Select pickup location'}
              </div>
              <div className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                {pickupDate ? pickupDate.toLocaleDateString() : 'Select date'}
              </div>
            </div>
            <FaChevronDown className={`text-gray-500 transform transition-transform duration-200 ${isExpanded ? 'rotate-180' : ''}`} />
          </div>
        </div>

        {/* Expanded Mobile View and Desktop View */}
        <div className={`${!isExpanded ? 'hidden lg:block' : ''}`}>
          <div className="flex flex-col space-y-4 w-full">
            {/* Pickup Location */}
            <div className="relative">
              <div className="relative">
                <input
                  ref={locationInputRef}
                  type="text"
                  placeholder="Pickup Location"
                  value={pickupLocation}
                  onChange={(e) => handleLocationChange(e.target.value, 'pickup')}
                  className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <FaMapMarkerAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
              {activeLocationInput === 'pickup' && locationSuggestions.length > 0 && (
                <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto">
                  {locationSuggestions.map((location) => (
                    <div
                      key={location.id}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleLocationSelect(location)}
                    >
                      <div className="font-medium">{location.name}</div>
                      <div className="text-sm text-gray-600">{location.address}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Dropoff Location */}
            <div className="relative">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Drop-off Location"
                  value={dropoffLocation}
                  onChange={(e) => handleLocationChange(e.target.value, 'dropoff')}
                  className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <FaMapMarkerAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
              {activeLocationInput === 'dropoff' && locationSuggestions.length > 0 && (
                <div className="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto">
                  {locationSuggestions.map((location) => (
                    <div
                      key={location.id}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleLocationSelect(location)}
                    >
                      <div className="font-medium">{location.name}</div>
                      <div className="text-sm text-gray-600">{location.address}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Date Selection */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative">
                <div className="relative">
                  <DatePicker
                    selected={pickupDate}
                    onChange={(date) => setPickupDate(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="MMM d, yyyy h:mm aa"
                    placeholderText="Pickup Date & Time"
                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    minDate={new Date()}
                  />
                  <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
              </div>
              <div className="relative">
                <div className="relative">
                  <DatePicker
                    selected={dropoffDate}
                    onChange={(date) => setDropoffDate(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    dateFormat="MMM d, yyyy h:mm aa"
                    placeholderText="Drop-off Date & Time"
                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    minDate={pickupDate || new Date()}
                  />
                  <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
              </div>
            </div>

            {/* Swap Button */}
            <div className="flex items-center justify-center">
              <button 
                type="button"
                onClick={swapLocations}
                className="bg-primary-500 text-white p-2 rounded-full hover:bg-primary-600 transition-colors"
              >
                <FaExchangeAlt className="text-sm" />
              </button>
            </div>

            {/* Search Button */}
            <div className="flex items-stretch">
              <button
                type="submit"
                className="w-full lg:w-auto bg-primary-500 text-white px-4 py-2 lg:px-6 lg:py-3 rounded-xl font-medium hover:bg-primary-600 transition-colors flex items-center justify-center space-x-2 text-sm lg:text-base"
              >
                <FaSearch className="text-sm" />
                <span>Search</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
