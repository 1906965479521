import React, { createContext, useContext, useState, useEffect } from 'react';
import { CompanySettings } from '../types/settings';
import { getCompanySettings, refreshCompanySettings } from '../services/settingsService';

interface SettingsContextType {
    settings: CompanySettings | null;
    loading: boolean;
    error: string | null;
    refreshSettings: () => Promise<void>;
}

const SettingsContext = createContext<SettingsContextType>({
    settings: null,
    loading: true,
    error: null,
    refreshSettings: async () => {},
});

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [settings, setSettings] = useState<CompanySettings | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const loadSettings = async () => {
        try {
            const data = await getCompanySettings();
            setSettings(data);
            setError(null);
        } catch (err) {
            setError('Failed to load company settings');
            console.error('Error loading settings:', err);
        } finally {
            setLoading(false);
        }
    };

    const refreshSettings = async () => {
        setLoading(true);
        try {
            const data = await refreshCompanySettings();
            setSettings(data);
            setError(null);
        } catch (err) {
            setError('Failed to refresh company settings');
            console.error('Error refreshing settings:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadSettings();
    }, []);

    return (
        <SettingsContext.Provider value={{ settings, loading, error, refreshSettings }}>
            {children}
        </SettingsContext.Provider>
    );
};
