import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  pricingService, 
  DurationDiscountResponse, 
  BasePricing,
  SeasonalPricing,
  DemandPricing,
  AdditionalCharge,
  PromotionalDiscount
} from '../services/pricingService';
import { bookingService } from '../services/api';

interface PricingState {
  durationDiscount: DurationDiscountResponse | null;
  basePricing: BasePricing[];
  seasonalPricing: SeasonalPricing[];
  demandPricing: DemandPricing[];
  additionalCharges: AdditionalCharge[];
  promotionalDiscounts: PromotionalDiscount[];
  loading: boolean;
  error: string | null;
}

const initialState: PricingState = {
  durationDiscount: null,
  basePricing: [],
  seasonalPricing: [],
  demandPricing: [],
  additionalCharges: [],
  promotionalDiscounts: [],
  loading: false,
  error: null,
};

export const calculateDurationDiscount = createAsyncThunk(
  'pricing/calculateDurationDiscount',
  async (data: {
    vehicle_type: string;
    start_date: string;
    end_date: string;
    daily_rate: number;
  }) => {
    const response = await pricingService.calculateDurationDiscount(data);
    return response;
  }
);

export const calculateTotalPrice = createAsyncThunk(
  'pricing/calculateTotalPrice',
  async (data: {
    vehicle_type: string;
    pickup_location_id: number;
    start_date: string;
    end_date: string;
    additional_services?: string[];
    promo_code?: string;
  }) => {
    const response = await pricingService.calculateTotalPrice(data);
    return response;
  }
);

export const createBooking = createAsyncThunk(
  'pricing/createBooking',
  async (bookingData: {
    vehicle_id: number; 
    start_date: string; 
    end_date: string; 
  }) => {
    const response = await bookingService.createBooking(bookingData);
    return response;
  }
);

export const fetchBasePricing = createAsyncThunk(
  'pricing/fetchBasePricing',
  async () => {
    const response = await pricingService.getBasePricing();
    return response;
  }
);

export const fetchSeasonalPricing = createAsyncThunk(
  'pricing/fetchSeasonalPricing',
  async () => {
    const response = await pricingService.getSeasonalPricing();
    return response;
  }
);

export const fetchDemandPricing = createAsyncThunk(
  'pricing/fetchDemandPricing',
  async () => {
    const response = await pricingService.getDemandPricing();
    return response;
  }
);

export const fetchAdditionalCharges = createAsyncThunk(
  'pricing/fetchAdditionalCharges',
  async () => {
    const response = await pricingService.getAdditionalCharges();
    return response;
  }
);

export const fetchPromotionalDiscounts = createAsyncThunk(
  'pricing/fetchPromotionalDiscounts',
  async () => {
    const response = await pricingService.getPromotionalDiscounts();
    return response;
  }
);

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    clearPricingData: (state) => {
      state.durationDiscount = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculateDurationDiscount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(calculateDurationDiscount.fulfilled, (state, action) => {
        state.loading = false;
        state.durationDiscount = action.payload;
      })
      .addCase(calculateDurationDiscount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to calculate duration discount';
      })
      .addCase(calculateTotalPrice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(calculateTotalPrice.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(calculateTotalPrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to calculate total price';
      })
      .addCase(createBooking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBooking.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create booking';
      })
      .addCase(fetchBasePricing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBasePricing.fulfilled, (state, action) => {
        state.loading = false;
        state.basePricing = action.payload;
      })
      .addCase(fetchBasePricing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch base pricing';
      })
      .addCase(fetchSeasonalPricing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSeasonalPricing.fulfilled, (state, action) => {
        state.loading = false;
        state.seasonalPricing = action.payload;
      })
      .addCase(fetchSeasonalPricing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch seasonal pricing';
      })
      .addCase(fetchDemandPricing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDemandPricing.fulfilled, (state, action) => {
        state.loading = false;
        state.demandPricing = action.payload;
      })
      .addCase(fetchDemandPricing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch demand pricing';
      })
      .addCase(fetchAdditionalCharges.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdditionalCharges.fulfilled, (state, action) => {
        state.loading = false;
        state.additionalCharges = action.payload;
      })
      .addCase(fetchAdditionalCharges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch additional charges';
      })
      .addCase(fetchPromotionalDiscounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPromotionalDiscounts.fulfilled, (state, action) => {
        state.loading = false;
        state.promotionalDiscounts = action.payload;
      })
      .addCase(fetchPromotionalDiscounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch promotional discounts';
      });
  },
});

export const { clearPricingData } = pricingSlice.actions;
export default pricingSlice.reducer;
