import React, { createContext, useContext, useState } from 'react';

interface NavigationContextType {
  isNavHidden: boolean;
  setIsNavHidden: (hidden: boolean) => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isNavHidden, setIsNavHidden] = useState(false);

  return (
    <NavigationContext.Provider value={{ isNavHidden, setIsNavHidden }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
