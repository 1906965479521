import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../redux/store';
import { fetchVehicles } from '../redux/vehicleSlice';
import { createNewBooking } from '../redux/bookingSlice';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';

const BookingPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { vehicles } = useSelector((state: RootState) => state.vehicles);
  const { user } = useSelector((state: RootState) => state.auth);

  const [selectedVehicle, setSelectedVehicle] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    dispatch(fetchVehicles({}));
  }, [dispatch]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedVehicle || !startDate || !endDate) {
      toast.error('Please fill in all required fields');
      return;
    }

    if (!user) {
      toast.error('Please log in to make a booking');
      navigate('/login');
      return;
    }

    try {
      await dispatch(createNewBooking({
        vehicle_id: Number(selectedVehicle),
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString()
      })).unwrap();

      toast.success('Booking created successfully!');
      navigate('/bookings');
    } catch (error) {
      toast.error('Failed to create booking. Please try again.');
    }
  };

  const availableVehicles = vehicles.filter(vehicle => vehicle.is_available);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-3xl font-bold mb-6">Book a Vehicle</h1>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Vehicle
            </label>
            <select
              value={selectedVehicle}
              onChange={(e) => setSelectedVehicle(e.target.value)}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
              required
            >
              <option value="">Select a vehicle</option>
              {availableVehicles.map((vehicle) => (
                <option key={vehicle.id} value={vehicle.id}>
                  {vehicle.brand} {vehicle.model} - ${vehicle.daily_rate}/day
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Start Date
              </label>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                minDate={new Date()}
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                placeholderText="Select start date"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                End Date
              </label>
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate || new Date()}
                className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
                placeholderText="Select end date"
                required
              />
            </div>
          </div>

          {selectedVehicle && startDate && endDate && (
            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold mb-2">Booking Summary</h3>
              {(() => {
                const vehicle = vehicles.find(v => v.id === Number(selectedVehicle));
                const days = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
                // Parse daily_rate to a number, handling both string and number cases
                const dailyRate = vehicle 
                  ? typeof vehicle.daily_rate === 'string' 
                    ? parseFloat(vehicle.daily_rate) 
                    : vehicle.daily_rate
                  : 0;
                const totalPrice = vehicle ? days * dailyRate : 0;

                return (
                  <div className="space-y-2">
                    <p><span className="font-medium">Duration:</span> {days} days</p>
                    <p><span className="font-medium">Daily Rate:</span> ${dailyRate.toFixed(2)}</p>
                    <p className="text-lg font-bold"><span className="font-medium">Total Price:</span> ${totalPrice.toFixed(2)}</p>
                  </div>
                );
              })()}
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-primary-500 text-white py-3 rounded-lg font-medium hover:bg-primary-600 transition-colors"
          >
            Confirm Booking
          </button>
        </form>
      </div>
    </div>
  );
};

export default BookingPage;
