import api from './api';
import { CompanySettings } from '../types/settings';

const SETTINGS_URL = '/settings/company-settings/';

export const settingsService = {
    async getCompanySettings(): Promise<CompanySettings> {
        const { data } = await api.get<CompanySettings>(SETTINGS_URL);
        return data;
    },
};

// Create a singleton instance of company settings
let companySettingsInstance: CompanySettings | null = null;

export const getCompanySettings = async (): Promise<CompanySettings> => {
    if (!companySettingsInstance) {
        companySettingsInstance = await settingsService.getCompanySettings();
    }
    return companySettingsInstance;
};

// Use this to force refresh the settings
export const refreshCompanySettings = async (): Promise<CompanySettings> => {
    companySettingsInstance = await settingsService.getCompanySettings();
    return companySettingsInstance;
};
